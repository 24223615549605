<template>
  <v-sheet
    style="width: 100%;height: auto;background: linear-gradient(180deg, #000000 0%, #404040 100%);"
    tile
    >
    <v-layout column align-center >
        <p class="mt-4"></p>
        <div class="whyTitle text--center" style="margin-right:10px;margin-left:10px">About Viba Academy</div>

      <v-row class="ma-4">

        <v-col
           cols="12"
           md="12"
         >
           <v-hover
             v-slot:default="{ hover }"
             open-delay="200"
           >
             <v-card
               :elevation="hover ? 16 : 2"
               :class="isMobileDevice ? 'pa-4' : 'pa-16'"
               style="background: linear-gradient(180deg, #291e00 0%, #705304 100%);border-radius:10px"
             >  
              <p class="cardTitle font-weight-bold mt-4">
                OUR STORY
              </p>
              <v-row>
                  <v-col cols="12" md="8" :class="isMobileDevice ? 'pr-2' : 'pr-8'">
                   <p class="cardText white--text font-weight-medium mt-2">
                    Brazilian jiu-jitsu (BJJ) and philosophy share a deep connection that goes beyond the physical aspects of the martial art. Here's an overview of the connection between BJJ and philosophy:<br><br>
                    1. Problem-Solving Mindset: BJJ requires practitioners to think critically and strategically. It teaches individuals to analyze situations, adapt to changing circumstances, and find effective solutions. This problem-solving mindset aligns with philosophical principles of critical thinking and intellectual growth.<br>
                    2. Focus on Personal Development: BJJ promotes personal growth and self-improvement. Practitioners are encouraged to develop mental resilience, discipline, and perseverance, which are also valued in philosophy. Both disciplines emphasize the pursuit of self-awareness, self-discovery, and the continuous quest for improvement.<br>
                    3. Emphasis on Ethics and Values: Philosophy often explores ethical theories and concepts, which can be reflected in the practice of BJJ. Respect, integrity, humility, and discipline are fundamental values in BJJ, fostering a strong moral foundation. These values align with many philosophical teachings and contribute to the character development of practitioners.<br>
                    4. Mind-Body Connection: BJJ emphasizes the connection between the mind and body. It requires practitioners to be present, focused, and in tune with their physical movements. This holistic approach resonates with philosophical traditions that explore the relationship between the mind and the body, such as phenomenology or mind-body dualism.<br><br>
                   </p>
                  </v-col>
                  <v-col cols="12" md="4" align-self="center" :class="isMobileDevice ? 'ma-4' : ''">
                    <v-img src="/img/home/about_01.png" :max-width="windowWidth - 40">
                    </v-img>
                  </v-col>
               </v-row>
               <v-row>
                <v-col cols="12" md="4" align-self="center" :class="isMobileDevice ? 'ma-4' : ''">
                    <v-img src="/img/home/about_02.jpeg" :max-width="windowWidth - 40">
                    </v-img>
                  </v-col>
                  <v-col cols="12" md="8" :class="isMobileDevice ? 'ma-2' : 'ma-8'">
                   <p class="cardText white--text font-weight-medium mt-2">
                    Regarding your question about "sparring" after classes, it's common for BJJ coaches to incorporate "sparring" sessions, also known as "rolling," into their training. Here are a few reasons why coaches include these sessions:<br><br>
                    1. Practical Application: Sparring allows practitioners to apply their techniques, test their skills, and develop their problem-solving abilities in a live, dynamic environment. It provides an opportunity to refine techniques and adapt them to different opponents.<br>
                    2. Physical and Mental Conditioning: Sparring sessions are physically demanding, helping practitioners improve their endurance, strength, and agility. They also foster mental resilience, as individuals face challenges, make split-second decisions, and learn to manage stress and pressure.<br>
                    3. Growth and Progression: Sparring enables practitioners to gauge their progress and identify areas for improvement. It helps develop their timing, reflexes, and awareness. Through sparring, individuals can refine their technique, learn from mistakes, and continually evolve as martial artists.<br>
                    4. Building Trust and Camaraderie: Sparring sessions often involve trust-building between training partners. Practitioners develop mutual respect, camaraderie, and a sense of community as they support each other in their BJJ journey.<br><br>
                    It's important to note that sparring should be conducted in a safe and controlled environment, with practitioners respecting each other's well-being and practicing proper techniques. Coaches play a crucial role in overseeing these sessions, ensuring the safety and growth of their students.<br>
                   </p>
                   </v-col>
              </v-row>
               <!-- <v-layout column align-center style="margin-top: -140px">
                 <v-img
                   :src="dark ? 'img/home/dachshund_card.png' : 'img/home/dachshund_card.png'"
                   class="white--text align-end"
                   style="opacity:0.1"
                   width="250"
                 >
                 </v-img>
                 <v-btn outlined :dark="dark" style="margin-top:5px">more on the tokenomics</v-btn>
               </v-layout> -->

             </v-card>
           </v-hover>
         </v-col>
        </v-row>

      <p class="mt-4"></p>
      <p class="mt-4"></p>
      <p class="mt-4"></p>
      <p class="mt-4"></p>
    </v-layout>
  </v-sheet>
</template>

<script>
export default {
  name: 'why',
  props: {
    dark: Boolean,
    windowWidth: Number,
    isMobileDevice: Boolean
  },
  data () {
    return {
      logoSrcWalletDark: '',
      logoSrcWalletLight: ''
    }
  },
  watch: {
  },
  created () {
    // this.windowWidth = this.$props.windowWidth
    // this.logoSrcWalletDark = './img/icons/my_wallet_new_dark.png'
    // this.logoSrcWalletLight = './img/icons/my_wallet_new_light.png'
    console.log('isMobileDevice')
    console.log(this.isMobileDevice)
  },
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Syncopate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Anton&family=Crimson+Text:wght@400;600;700&display=swap');

.whyTitle {
  font-family: 'Comfortaa';
  color:#faa61e;
  font-size: 40px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.cardTitle {
  font-family: 'Crimson Text';
  color:#FFF;
  font-size: 32px;
  font-weight: 700;
  // text-transform: uppercase;
}
.cardText {
  font-family: 'Crimson Text';
  color:#FFF;
  font-size: 24px;
  font-weight: 700;
  text-align: justify;
  // text-transform: uppercase;
}

</style>
