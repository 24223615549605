<template>
  <div class="home" id="home">
    <v-slide-y-transition mode="out-in">
      <v-layout column >
        <!-- ####################### SKELETON LOADERS ####################### -->
        <v-layout column v-if="loading" style="margin-top:-64px">

        </v-layout>

        <!-- ############################################## COMPONENTS F27Xriy1SDoDDnaaXbb9 ##############################################  -->

        <v-layout column v-else style="margin-top:-64px">

          <v-layout column >
            <v-card flat
              style="border-radius:0px"
              color="black"
              :min-width="windowWidth < 768 ? '100%' : '100%'"
              min-height="350"
              :max-height="windowHeight"
            >
              <v-img
                :src="isMobileDevice ? 'img/home/home_bg_01_mobile.jpeg' : 'img/home/home_bg_01.png'"
                gradient="to bottom, rgba(0,0,0,.0), rgba(0,0,0,.4)"
                :height="windowHeight"
                :max-height="windowHeight"
              >

                <v-row :align="'center'" style="margin-top: 250px;" v-if="!isMobileDevice">
                  <v-col :align="'center'">
                    <span style="font-size: 60px;color:#b0850a;font-weight: bold;">
                      Welcome to Viba Academy!<br>The pure art of Brazilian Jiu-Jitsu.
                    </span>
                  </v-col>
                </v-row>

                <v-row :align="'center'" style="margin-top: 60px;" v-if="isMobileDevice">
                  <v-col :align="'center'">
                    <span style="font-size: 40px;color:#b0850a;font-weight: bold;text-shadow: #ffe396 1px 0 2px;">
                      Welcome to Viba Academy!<br>The pure art of Brazilian Jiu-Jitsu.
                    </span>
                  </v-col>
                </v-row>

                <v-layout column style="position:absolute; bottom:50px;width: 100%;"
                          v-if="!isMobileDevice && show">

                  <!-- <span style="margin-bottom:50px;" class="homeTextLarge">Everybody likes me!</span> -->

                  <transition name="fade">
                    <v-layout style="width: 100%;">
                      <v-spacer></v-spacer>
                      <v-btn style="width:250px;" color="grey lighten-3" x-large @click="signUpDialog = true">Contact US</v-btn>
                      <v-spacer style="width:50px"></v-spacer>
                      <v-btn style="width:250px;" color="grey lighten-3"  outlined v-scroll-to="'#about'" x-large>Follow Us</v-btn>
                      <v-spacer></v-spacer>
                      <v-btn style="width:250px;" color="grey lighten-3" v-scroll-to="'#about'" x-large>Learn More</v-btn>
                      <v-spacer></v-spacer>
                    </v-layout>
                  </transition>
                </v-layout>

                <v-layout column pa-2 v-if="isMobileDevice && show" style="position:absolute; bottom:20px;width: 100%;">
                 <v-layout fill-height ></v-layout>
                  <transition name="fade">
                    <v-layout column style="width: 100%;">

                      <v-btn style="width:100%;" color="grey lighten-3" x-large @click="signUpDialog = true">Contact US</v-btn>

                      <v-btn class="mt-2" style="width:100%;" color="grey lighten-3"  outlined v-scroll-to="'#about'" x-large>Follow Us</v-btn>

                      <v-btn class="mt-2" style="width:100%;" color="grey lighten-3" v-scroll-to="'#about'" x-large>Learn More</v-btn>

                    </v-layout>
                  </transition>
                </v-layout>
              </v-img>
            </v-card>

       
          </v-layout>

          <About id="about" 
                 :dark="dark" 
                 :windowWidth="windowWidth"
                 :isMobileDevice="isMobileDevice"
          >
          </About>

          <!-- <Tokenomics id="tokenomics" :dark="dark" :windowWidth="windowWidth"></Tokenomics> -->

          <!--  <Timeline id="when" :dark="dark" :windowWidth="windowWidth"></Timeline> -->

          <!-- Collect Samples -->

          <Footer id="contact" :dark="dark" :windowWidth="windowWidth" v-on:signUpClicked="signUpDialog = true"
                   v-on:termsClicked="openTerms" v-on:privacyClicked="openPrivacy"></Footer>

        </v-layout>

      </v-layout>
    </v-slide-y-transition>
  </div>
</template>

<script>
// @ is an alias to /src
import About from './About'
// import Tokenomics from './Tokenomics'
// import Timeline from './Timeline'
import Footer from './Footer'
export default {
  name: 'Home',
  props: {
    dark: Boolean,
    windowWidth: Number,
    windowHeight: Number,
    isMobileDevice: Boolean,
    drawer: Boolean,
    welcome: String,
    loadVideo: Boolean
  },
  data () {
    return {
      // lang: this.$store.state.lang,
      loading: true,
      loadingData: false,
      show: false,
      srcdachshund: '',
      srcTikTok: ''
    }
  },
  computed: {
    // getLanguage () {
      // return this.$store.state.user.language
    // },
    getTheme () {
      return this.$store.state.navigation.theme
    }
  },
  components: {
    About,
    // Tokenomics,
    // Timeline,
    Footer
  },
  created () {
    this.init ()
    console.log('isMobileDevice')
    console.log(this.isMobileDevice)
  },
  methods: {
    init () {
      this.loading = false
      this.showButtons()
      this.srcdachshund = './img/icons/dachshund_light_orange.png'
      this.srcdachshundWhite = './img/icons/dachshund_white.png'
      this.srcTikTok = './img/icons/tiktok_light_orange.png'
      this.srcTikTokWhite = './img/icons/tiktok_white.png'
    },
    openTerms () {

    },
    openPrivacy () {

    },
    showButtons () {
      this.show = false
      setTimeout(() => {
        this.show = true
      }, 3000)
    },
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Syncopate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500&display=swap');

.homeTextLarge {
  font-family: 'Comfortaa';
  color:#FFF;
  font-size: 28px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}
.homeText {
  font-family: 'Comfortaa';
  color:#FFF;
  font-size: 18px;
  font-weight: 900;
  text-align: center;
  // text-transform: uppercase;
}

</style>
