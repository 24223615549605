<template>
  <v-app id="viba">
    <v-navigation-drawer
      v-model="drawer"
      app
      :dark="dark"
      :color="dark ? 'grey darken-4' : 'grey lighten-3'"
      :width="show ? '280' : '0'"
    >

    <!-- <v-btn dark icon @click.stop="drawer = !drawer" style="margin:10px">
      <v-icon>mdi-window-close</v-icon>
    </v-btn> -->

    <v-list :dense="window.width < 770">
      <v-layout column align-center pl-2 pr-2>
        <p class="mt-0"></p>
        <v-img style="cursor: pointer;" v-scroll-to="'#home'" @click.stop="routerGo('/')" v-if="dark" :src="logoSrcDark" width="150" height="150"></v-img>
        <v-img style="cursor: pointer;" v-scroll-to="'#home'" @click.stop="routerGo('/')" v-if="!dark" :src="logoSrc" width="150" height="150"></v-img>
        <!-- <span class="body-2" style="font-style: italic;">{{ lang[getLanguage].SLOGAN }}</span> -->
        <span style="font-size: 30px;color:#b0850a;">Viba Academy</span>
        <p class="mt-0"></p>
      </v-layout>

      <!-- <v-list-item class="text-center">
        <v-layout column align-center>
          <v-img :src="dark ? 'logoSrcDark' : 'logoSrcLight'" max-width="180" max-height="35"></v-img>
          <p class="mt-0"></p>
        </v-layout>
      </v-list-item> -->

    <!-- <v-divider></v-divider> -->

    <p class="mt-0"></p>

    <!-- <v-list-item v-show="showHome && !isLoggedIn">
      <v-layout>
        <v-spacer></v-spacer>
        <v-btn :style="dark ? 'width:190px;color:#212121' : 'width:190px;color:#EEEEEE'" depressed :color="dark ? 'grey lighten-3' : getTheme" small>Connect</v-btn>
        <v-spacer></v-spacer>
      </v-layout>
    </v-list-item> -->

    <v-list-item v-show="showHome"
       link v-scroll-to="'#home'" v-on:click="menuChangeClicked" to="/"
    >
      <v-list-item-icon>
        <v-icon :color="dark ? 'grey lighten-4' : 'grey darken-4'">mdi-home</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title :style="dark ? 'color:#EEEEEE' : 'color:#212121'">Home</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-show="showHome"
       link v-scroll-to="'#about'" v-on:click="menuChangeClicked"
    >
      <v-list-item-icon>
        <v-icon :color="dark ? 'grey lighten-4' : 'grey darken-4'">mdi-crosshairs-question</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title :style="dark ? 'color:#EEEEEE' : 'color:#212121'">About</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-show="showHome"
       link v-scroll-to="'#tokenomics'" v-on:click="menuChangeClicked"
    >
      <v-list-item-icon>
        <v-icon :color="dark ? 'grey lighten-4' : 'grey darken-4'">mdi-map-marker-radius</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title :style="dark ? 'color:#EEEEEE' : 'color:#212121'">Location</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-show="showHome"
       link v-scroll-to="'#when'" v-on:click="menuChangeClicked"
    >
      <v-list-item-icon>
        <v-icon :color="dark ? 'grey lighten-4' : 'grey darken-4'">mdi-fireplace-off</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title :style="dark ? 'color:#EEEEEE' : 'color:#212121'">Our Dojo</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-show="showHome"
       link v-scroll-to="'#gallery'" v-on:click="menuChangeClicked" to="/gallery"
    >
      <v-list-item-icon>
        <v-icon :color="dark ? 'grey lighten-4' : 'grey darken-4'">mdi-multimedia</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title :style="dark ? 'color:#EEEEEE' : 'color:#212121'">Gallery</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <v-list-item v-show="showHome"
       link v-scroll-to="'#charity'" v-on:click="menuChangeClicked"
    >
      <v-list-item-icon>
        <v-icon :color="dark ? 'grey lighten-4' : 'grey darken-4'">mdi-human-male-child</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title :style="dark ? 'color:#EEEEEE' : 'color:#212121'">For Kids</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <!-- <v-list-item v-show="showHome"
       link v-scroll-to="'#whitepaper'" v-on:click="menuChangeClicked"
    >
      <v-list-item-icon>
        <v-icon :color="dark ? 'grey lighten-4' : 'grey darken-4'">mdi-file-multiple-outline</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title :style="dark ? 'color:#EEEEEE' : 'color:#212121'">Whitepaper</v-list-item-title>
      </v-list-item-content>
    </v-list-item> -->

    <v-list-item v-show="showHome"
       link v-scroll-to="'#contact'" v-on:click="menuChangeClicked"
    >
      <v-list-item-icon>
        <v-icon :color="dark ? 'grey lighten-4' : 'grey darken-4'">mdi-at</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title :style="dark ? 'color:#EEEEEE' : 'color:#212121'">Contact</v-list-item-title>
      </v-list-item-content>
    </v-list-item>

    <p class="mt-0"></p>

  </v-list>


    </v-navigation-drawer>

    <v-app-bar
      :color="!scrolledHome ? 'transparent' : dark ? 'grey darken-4' : 'grey lighten-3'"
      flat
      fixed
      app
      :dark="dark"
      :clipped-left="clipped"
    >
      <v-btn v-show="show"  @click.stop="drawer = !drawer" icon style="margin-right:10px">
        <v-icon x-large :color="dark ? 'grey lighten-3' : getTheme" >{{ !drawer ? 'mdi-menu' : 'mdi-menu-open' }}</v-icon>
      </v-btn>

      <v-img style="cursor: pointer;" v-scroll-to="'#home'" @click.stop="routerGo('/')" v-show="!dark && !drawer && scrolledHome" :src="logoSrc" max-width="50" max-height="50"></v-img>
      <v-img style="cursor: pointer;" v-scroll-to="'#home'" @click.stop="routerGo('/')" v-show="dark && !drawer && scrolledHome" :src="logoSrcDark" max-width="50" max-height="50"></v-img>

      <v-spacer></v-spacer>
      <v-toolbar-items  v-show="showHome && !drawer && $route.name !== 'home'" class="hidden-sm-and-down" v-if="window.width > 770">
        <v-btn style="font-size:18px" text v-scroll-to="'#home'" v-on:click="menuChangeClicked">Home</v-btn>
        <v-spacer></v-spacer>
        <v-btn style="font-size:18px" text v-scroll-to="'#about'" v-on:click="menuChangeClicked">About</v-btn>
        <v-spacer></v-spacer>
        <v-btn style="font-size:18px" text v-scroll-to="'#tokenomics'" v-on:click="menuChangeClicked">Location</v-btn>
        <v-spacer></v-spacer>
        <v-btn style="font-size:18px" text v-scroll-to="'#when'" v-on:click="menuChangeClicked">Our DoJo</v-btn>
        <v-spacer></v-spacer>
        <v-btn style="font-size:18px" text v-scroll-to="'#collect'" v-on:click="menuChangeClicked">Gallery</v-btn>
        <v-spacer></v-spacer>
        <v-btn style="font-size:18px" text v-scroll-to="'#contact'" v-on:click="menuChangeClicked">Contact</v-btn>
        <v-spacer></v-spacer>
        <!-- <v-chip
          class="mt-4"
          color="pink"
          x-small
        >
          <span style="color:#FFF">{{ lang[getLanguage].COMING_SOON }}</span>
        </v-chip> -->
      </v-toolbar-items>
      <v-btn @click.stop="dark = !dark" icon><v-icon >mdi-invert-colors</v-icon></v-btn>
    </v-app-bar>

    <v-main>
      <router-view :dark="dark" :windowWidth="window.width" :windowHeight="window.height" v-on:searchCleared="clearSearch"
                   :search="search" :trigger="triggerSearch" :drawer="drawer" :isMobileDevice="isMobile"
                   v-on:termsClicked="openTerms" v-on:privacyClicked="openPrivacy"/>
    </v-main>

    <v-footer v-show="window.width > 770 && scrolledHome"
      :style="dark ? 'opacity: 0.8' : 'opacity: 0.8'"
      app
      color="grey lighten-5"
    >
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <span class="body-2" >&copy; Copyright {{ new Date().getFullYear() }}. Viba Academy - All Rights Reserved.</span>
    </v-footer>

  </v-app>
</template>

<script>
import store from '@/store/index'
import { isMobile } from 'mobile-device-detect';
export default {
  data: () => ({
    lang: '',
    drawer: true,
    clipped: false,
    scrolled: false,
    scrolledHome: false,
    isMobileDevice: false,
    isMobile: false,
    dark: true,
    search: '',
    triggerSearch: false,
    showSearch: false,
    logoSrcHeadDark: '',
    logoSrc: '',
    window: {
      width: 0,
      height: 0
    },
  }),
  computed: {
    // getLanguage () {
      // return this.$store.state.user.language
    // },
    show () {
      return true
    },
    showHome () {
      return this.$store.state.navigation.showHome
    },
    getDark () {
      return this.$store.state.navigation.dark
    },
    getTheme () {
      return this.$store.state.navigation.theme
    },
  },
  mounted () {
    this.dark = this.getDark
  },
  created () {
    // this.lang = this.$store.state.lang
    this.isMobile = isMobile;
    this.isMobileDevice = isMobile;
    this.isMobileDevice ? this.drawer = false : this.isMobileDevice = true
    document.addEventListener("deviceready", this.onDeviceReady, false)
    window.addEventListener('resize', this.handleResize)
    window.addEventListener('scroll', this.handleScroll)
    this.handleResize()
    this.logoSrc = './img/logo/logo_01.png'
    this.logoSrcDark = './img/logo/logo_01.png'
  },
  destroyed () {
    window.removeEventListener('resize', this.handleResize)
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    onDeviceReady() {
      // console.log(device.cordova)
      // console.log(device.model)
      // console.log(device.platform)
      store.commit('SetDevice', {
        // platform: device.platform
      })
    },
    routerGo(route) {
      if(this.isMobileDevice){
        this.drawer = false;
      }
      if (this.$router.currentRoute.path !== route) {
        this.$router.push(route);
      }
    },
    showSearchField () {
      this.showSearch = true
      setTimeout(() => {
        this.$refs.search.focus()
      })
    },
    clearSearch () {
      this.search = ''
      this.triggerSearch = false
    },
    checkMetaMaskChanges (event) {
      // console.log('Something Changed')
      // console.log(event)
      if (!event.isUnlocked && !event.isEnabled) {
        // Unlock MetaMask View
        store.commit('setWeb3Null', null)
      } else if (event.isUnlocked && !event.isEnabled) {
        // Connect View
        store.commit('setWeb3Null', null)
      } else if (event.isUnlocked && event.isEnabled) {
        // Connected
        /* Promise.resolve(Web3Getter.getWeb3Instance())
          .then(result => {
            // console.log('connect getWeb3Instance 2')
            // console.log(result)
            store.commit('setWeb3', result)
            // Next Firebase Sign Up stuff in store Action / Mutation
          }) */
      }
    },
    changeLanguage () {
      // console.log(this.getLanguage)
      store.commit('SetLanguage', {
        language: this.getLanguage === 'en' ? 'id' : 'en'
      })
      /* this.userData.language = lang
      var obj = {
        language: lang
      }
      this.saveSettingsData(obj) */
    },
    openPrivacy () {
      // this.readFile()
      if (this.getLanguage === 'en') {
        this.fileName = 'https://olahbola.com/privacy/OlahBola%20Privacy%20Policy%20ENG.pdf'
      } else if (this.getLanguage === 'id') {
        this.fileName = 'https://olahbola.com/privacy/OlahBola%20Privacy%20Policy%20BAHASA.pdf'
      }
      this.dialogFilePreview = true
      /* if (device.platform === 'Android') {
        window.open(this.fileName, '_blank')
      } else {
        this.dialogFilePreview = true
      } */
    },
    openTerms () {
      // this.readFile()
      if (this.getLanguage === 'en') {
        this.fileName = 'https://olahbola.com/terms/OlahBola%20Terms%20and%20Conditions%20ENG.pdf'
      } else if (this.getLanguage === 'id') {
        this.fileName = 'https://olahbola.com/terms/OlahBola%20Terms%20and%20Conditions%20BAHASA.pdf'
      }
      this.dialogFilePreview = true
      /* if (device.platform === 'Android') {
        window.open(this.fileName, '_blank')
      } else {
        this.dialogFilePreview = true
      } */
    },
    signOut () {
      /* firebase.auth().signOut().then(() => {
        this.$router.push('/')
        window.location.reload()
      }) */
    },
    handleResize () {
      this.window.width = window.innerWidth
      this.window.height = window.innerHeight
      // console.log(this.window.width)
      // this.window.width > 770 ? this.drawer = false : this.drawer = true
    },
    menuChangeClicked () {
      if  (window.width < 770)  {
        this.drawer = false
      }
    },
    handleScroll () {
      console.log(window.scrollY)
      if (window.scrollY > 450) {
        this.scrolled = true
      } else {
        this.scrolled = false
      }
      if (window.scrollY > 300) {
        this.scrolledHome = true
      } else {
        this.scrolledHome = false
      }
    }
  }
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Syncopate&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;500&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap');
#viba {
  font-family: 'Lora';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: #212121;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.appname { // text-shadow: 0 0 2px rgba(167, 116, 149, 1),  0 0 20px rgba(10, 175, 230, 0);
    font-family: 'Syncopate', sans-serif; // Font Brown
    letter-spacing: 0.25em;
    font-size: 80px;
}
</style>
