import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    navigation: {
      show: true,
      showHome: true,
      showApp: false,
      dark: true, // set and get
      theme: '#463123', // set and get
      yellow: '#faa61e',
      orange: '#f6743a',
      gold: '#ad8d30'

    },
    device: {
      platform: 'NaN'
    }
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
