var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"pa-0":"","fluid":""}},[_c('v-sheet',{staticClass:"mx-auto",staticStyle:{"width":"100%","height":"auto"},attrs:{"max-width":"100%","tile":"","color":_vm.dark ? '#312e2e' : 'grey lighten-4',"dark":_vm.dark}},[_c('v-layout',{style:(_vm.windowWidth < 800 ? '' : 'margin-right:80px;margin-left:80px'),attrs:{"column":""}},[_c('p',{staticClass:"mt-3"}),_c('p',{staticClass:"sectionHeader text-center"},[_vm._v("Contact Information and Useful Links")]),_c('p',{staticClass:"mt-2"}),_c('p',{staticClass:"text-center"},[_vm._v("Learn more about Viba Academy, chat with the team and the community."),_c('br'),_vm._v("Have your say in shaping the future.")]),_c('v-row',{staticStyle:{"margin-top":"10px"}},[_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",staticStyle:{"background":"linear-gradient(180deg, #754904 0%, #faa61e 100%)","border-radius":"10px"},attrs:{"elevation":hover ? 16 : 2,"height":"200","max-width":"350"}},[_c('v-layout',{attrs:{"column":"","align-center":"","pa-4":""}},[_c('p',{staticClass:"white--text text-center font-weight-medium mt-2",staticStyle:{"padding-left":"10px","padding-right":"10px","font-size":"16px"}},[_vm._v(" Email us for any inquiry or questions. "),_c('br'),_vm._v("We are happy to answer! ")]),_c('v-btn',{staticClass:"font-weight-medium",staticStyle:{"font-size":"13px","text-transform":"none !important"},attrs:{"icon":""},on:{"click":function($event){return _vm.openLink('mailto:hello@Viba Academy.community')}}},[_c('v-icon',{attrs:{"color":_vm.dark ? '' : '#454545',"size":"70"}},[_vm._v("mdi-email")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",staticStyle:{"background":"linear-gradient(180deg, #754904 0%, #faa61e 100%)","border-radius":"10px"},attrs:{"elevation":hover ? 16 : 2,"height":"200","max-width":"350"}},[_c('v-layout',{attrs:{"column":"","align-center":"","pa-4":""}},[_c('p',{staticClass:"white--text text-center font-weight-medium mt-2",staticStyle:{"padding-left":"10px","padding-right":"10px","font-size":"16px"}},[_vm._v(" Chat in real-time with the Viba Academy community ")]),_c('p',{staticClass:"mt-2"}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openLink('https://t.me/Viba Academy')}}},[_c('v-icon',{attrs:{"size":"70"}},[_vm._v("mdi-telegram")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",staticStyle:{"background":"linear-gradient(180deg, #754904 0%, #faa61e 100%)","border-radius":"10px"},attrs:{"elevation":hover ? 16 : 2,"height":"200","max-width":"350"}},[_c('v-layout',{attrs:{"column":"","align-center":"","pa-4":""}},[_c('p',{staticClass:"white--text text-center font-weight-medium mt-2",staticStyle:{"padding-left":"10px","padding-right":"10px","font-size":"16px"}},[_vm._v(" Follow @Viba Academy for updates and news. ")]),_c('p',{staticClass:"mt-2"}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openLink('https://twitter.com/Viba Academy')}}},[_c('v-icon',{attrs:{"size":"70"}},[_vm._v("mdi-twitter")])],1)],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","sm":"3"}},[_c('v-hover',{attrs:{"open-delay":"200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"mx-auto",staticStyle:{"background":"linear-gradient(180deg, #754904 0%, #faa61e 100%)","border-radius":"10px"},attrs:{"elevation":hover ? 16 : 2,"height":"200","max-width":"350"}},[_c('v-layout',{attrs:{"column":"","align-center":"","pa-4":""}},[_c('p',{staticClass:"white--text text-center font-weight-medium mt-2",staticStyle:{"padding-left":"10px","padding-right":"10px","font-size":"16px"}},[_vm._v(" Talk, chat, hang out, and stay close with the community. ")]),_c('p',{staticClass:"mt-2"}),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.openLink('https://www.reddit.com/r/Viba Academy/')}}},[_c('v-icon',{attrs:{"size":"70"}},[_vm._v("mdi-reddit")])],1)],1)],1)]}}])})],1)],1),_c('p',{staticClass:"mt-4"}),_c('p',{staticClass:"mt-4"})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }